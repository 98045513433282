/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import dayjs from "dayjs";
import { Card, Col, Divider, Progress, Row, Typography } from "antd";

import { useAuthState } from "../providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { BaseButton } from "../components";
import { COLORS } from "../constants";
import Pause from "../images/pause.png";
import Coppa from "../images/coppa.png";

const SurveyResult = () => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const { t } = useTranslation();
  const history = useHistory();
  const { surveyProgress, user } = useAuthState();

  const { Title } = Typography;

  const handleProfile = () => history.push("/profile");
  const handleHome = () => history.push("/");
  const handleSurvey = () => history.push("/survey");

  return (
    <>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="card-style p-0 card-border"
          style={{
            margin: "auto",
            borderRadius: "20px",
            backgroundColor: "#e7f1f4",
          }}
        >
          <div className="title-blue container-result-survey">
            <span style={{ fontSize: "28px" }}>
              <strong>{t("test").toUpperCase()}</strong>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "24px",
              }}
            >
              <strong>{`${surveyProgress()}%`}</strong>
            </div>
          </div>
          <Row justify="center">
            <Col span={24}>
              <Progress
                className="no-border-progress"
                strokeLinecap="butt"
                strokeColor={COLORS.primary}
                trailColor={COLORS.lightGray}
                strokeWidth={10}
                percent={surveyProgress()}
                showInfo={false}
                style={{ marginTop: "-10px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>
            </Col>
          </Row>
          <div style={{ textAlign: "center" }}>
            {surveyProgress() === 100 ? (
              <img src={Coppa} alt="pause" style={{ width: "100px" }} />
            ) : (
              <img src={Pause} alt="pause" style={{ width: "100px" }} />
            )}
          </div>
          <Row justify="center" style={{ margin: "20px 0px" }}>
            <Col xl={{ span: 16 }} xs={{ span: 22 }}>
              <div
                style={{
                  backgroundColor: "#FFF",
                  borderRadius: "10px",
                  padding: "10px",
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <div
                  style={{
                    border: "2px solid #035E86",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  <p
                    className="title-blue"
                    style={{ fontSize: "36px", marginBottom: "5px" }}
                  >
                    <strong>
                      {t("message_survey_result_congratulations").toUpperCase()}
                    </strong>
                  </p>
                  <p style={{ fontSize: "20px", marginBottom: "0px" }}>
                    {surveyProgress() === 100
                      ? t("message_survey_result_score_complete_intro")
                      : t("message_survey_result_score_pause_intro")}
                  </p>
                  <Title
                    className="title-blue"
                    level={1}
                    style={{ margin: "0px 0px" }}
                  >
                    {surveyProgress()}%
                  </Title>
                </div>
              </div>
              <p
                className="title-blue"
                style={{ fontSize: "20px", margin: "20px 0px" }}
              >
                {surveyProgress() === 100
                  ? t("message_survey_result_score_complete_outro")
                  : t("message_survey_result_score_pause_outro")}
              </p>
            </Col>
          </Row>

          {/* {surveyProgress() === 100 && (
            <Row justify="center">
              <Col xl={{ span: 16 }} x={{ span: 24 }}>
                <Title
                  level={5}
                  style={{ margin: "0px 20px", textAlign: "center" }}
                >
                  {t("message_survey_result_redo_instruction", {
                    expirationDate: dayjs.utc(user.expire).format("DD/MM/YYYY"),
                  })}
                </Title>
              </Col>
            </Row>
          )} */}

          <Divider style={{ border: "2px solid #035E86" }} />
          <div>
            {surveyProgress() === 100 ? (
              <Row justify="center" style={{ padding: "10px 0px" }}>
                <Col xl={{ span: 8 }} xs={{ span: 18 }}>
                  <BaseButton
                    className="shadow-button"
                    type="primary"
                    block
                    shape="round"
                    size="large"
                    onClick={handleProfile}
                  >
                    {t("profile").toUpperCase()}
                  </BaseButton>
                </Col>
              </Row>
            ) : (
              <React.Fragment>
                <Row justify="center" style={{ padding: "10px 0px" }}>
                  <Col xl={{ span: 4 }} xs={{ span: 13 }}>
                    <BaseButton
                      className="shadow-button"
                      type="primary"
                      block
                      shape="round"
                      size="large"
                      onClick={handleHome}
                    >
                      {t("close").toUpperCase()}
                    </BaseButton>
                  </Col>
                  <Col xl={{ span: 4, offset: 1 }} xs={{ span: 13 }}>
                    <BaseButton
                      className="shadow-button"
                      type="primary"
                      block
                      shape="round"
                      size="large"
                      onClick={handleSurvey}
                    >
                      {t("continue").toUpperCase()}
                    </BaseButton>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SurveyResult;
