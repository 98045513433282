import React from "react";
import { Link } from "react-router-dom";

import { Col, Row, Space } from "antd";
import { LinkedinOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const MainFooter = () => {
  const current_year = dayjs().year();

  return (
    <>
      <Row
        justify="center"
        style={{ padding: "10px 20px 0px 20px" }}
        className="title-blue"
      >
        <Col xl={{ span: 5 }} md={{ span: 8 }} xs={{ span: 24 }}>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            TAKEFLIGHT srl
          </span>
          <p style={{ fontSize: "12px" }}>
            Via Serrabella, 17 <br />
            40067, Sasso Marconi (BO), Italy <br />
            P.IVA: IT03622931206 <br />
            REA: BO-533754 <br />
            Cap.Sociale: 30.000€ i.v.
          </p>
        </Col>
        <Col xl={{ span: 3 }} md={{ span: 8 }} xs={{ span: 24 }}>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>CONTATTI</span>

          <p style={{ fontSize: "12px" }}>
            Mail:{" "}
            <a href="mailto:takeflight@takeflight.it">
              takeflight@takeflight.it
            </a>{" "}
            <br />
            Whatsapp: 349.44.76.384
          </p>
        </Col>

        <Col xl={{ span: 2 }} md={{ span: 2, offset: 3 }} xs={{ span: 24 }}>
          <Space direction="vertical" style={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>SOCIAL</span>
            <a
              href="https://it.linkedin.com/company/takeflight-kairos-method"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinOutlined style={{ fontSize: "30px" }} />
            </a>
          </Space>
        </Col>
      </Row>
      <Row
        style={{ backgroundColor: "#035E86", color: "#FFF", padding: "5px" }}
        justify="center"
      >
        <Col>
          ©{current_year} Takeflight Srl – Tutti i diritti riservati |
          <Link to="/privacypolicy" style={{ color: "#FFF" }}>
            {" "}
            Privacy Policy |{" "}
          </Link>
          <Link to="/cookiepolicy" style={{ color: "#FFF" }}>
            Cookie Policy
          </Link>
        </Col>
      </Row>
    </>
  );
};
export default MainFooter;
