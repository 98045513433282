/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";

import { Card, Col, Row, Typography } from "antd";
import Success from "../images/confirm.png";
import { useTranslation } from "react-i18next";
import { BaseButton } from "../components";
import { useHistory } from "react-router-dom";

const RegistrationSuccess = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="container">
      <div
        className="card-style p-0"
        style={{
          border: "0px solid",
          margin: "auto",
          borderRadius: "20px",
        }}
      >
        <Row align="middle" justify="center">
          <Col
            className="col-blue"
            md={{ span: 12 }}
            xs={{ span: 24 }}
            align="center"
            style={{
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={Success} alt="LoginImg" width="70%" height="70%" />
          </Col>
          <Col
            md={{ span: 12 }}
            xs={{ span: 24 }}
            className="col-lightblue"
            style={{
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Row justify="center">
              <Col span={18}>
                <Title
                  className="title-blue"
                  level={2}
                  style={{ textAlign: "center" }}
                >
                  {t("label_finish").toUpperCase()}
                </Title>
                <Title level={5} className="title-blue">
                  <strong>{t("label_registration_success")}</strong>
                </Title>
                <Title
                  level={5}
                  style={{ marginBottom: "30px" }}
                  className="title-blue"
                >
                  <strong>{t("label_good_study")}</strong>
                </Title>
                <Row justify="center">
                  <Col
                    xl={{ span: 12 }}
                    lg={{ span: 16 }}
                    md={{ span: 14 }}
                    xs={{ span: 20 }}
                  >
                    <BaseButton
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      block
                      size="large"
                      onClick={() => history.push("/")}
                    >
                      {t("label_begin").toUpperCase()}
                    </BaseButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default RegistrationSuccess;
