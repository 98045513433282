import React from "react";
import { Col, Form, Input, Row, Select } from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";

import { BaseButton } from "../components";
import client from "../kairosClient";
import { months, years, schools, gender } from "../constants";

const DataUser = ({ onSuccess, onError, children, buttonLabel }) => {
  const { t } = useTranslation();
  const { refresh, token, user } = useAuthState();
  const { Option } = Select;

  const handleSubmit = (values) => {
    console.log("DataUser.handleSubmit", values);
    client.user
      .updateUserData(user.id, values, token)
      .then((res) => (res && res.size > 0 ? res.json() : res.text()))
      .then((response) => {
        console.log("DataUser.onSubmit response", response);
        if (response.eCode) {
          console.error("DataUser.onSubmit error", response); //ok
          //showError(response);
          //logUserUpdate(STATUS.ERROR);
        } else {
          console.log("DataUser.onSubmit successful", response);
          //logUserUpdate(STATUS.SUCCESS);
          refresh();
          onSuccess();
        }
      })
      .catch((error) => {
        error["eCode"] = 999;
        onError(error);
        //logUserUpdate(STATUS.ERROR);
        console.error("DataUser.onSubmit unpredictable error", error); //ok
      });
  };

  return (
    <Form
      size="large"
      layout="vertical"
      style={{ padding: "30px 0px 0px" }}
      onFinish={handleSubmit}
      initialValues={{
        name: user.name,
        surname: user.surname,
        birthMonth: user.birthMonth,
        birthYear: user.birthYear,
        schoolType: user.schoolType,
        gender: user.gender,
      }}
    >
      <p className="paragraph_form">
        <strong>{t("label_your_name")}</strong>
      </p>
      <Row gutter={16}>
        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: t("message_insert_name") }]}
          >
            <Input placeholder={t("label_name")} />
          </Form.Item>
        </Col>
        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="surname"
            rules={[{ required: true, message: t("message_insert_surname") }]}
          >
            <Input placeholder={t("label_surname")} />
          </Form.Item>
        </Col>
      </Row>
      <p className="paragraph_form">
        <strong>{t("label_date_birth")}</strong>
      </p>
      <Row gutter={16}>
        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="birthMonth"
            rules={[{ required: true, message: t("message_insert_month") }]}
          >
            <Select placeholder={t("label_month")}>
              {months.map((m, i) => (
                <Option key={`opt-month-${m}`} value={i + 1}>
                  {t(m)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="birthYear"
            rules={[{ required: true, message: t("message_insert_year") }]}
          >
            <Select placeholder={t("label_year")}>
              {years.map((y) => (
                <Option key={`opt-year-${y}`} value={y}>
                  {y}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <p className="paragraph_form">
            <strong>{t("gender")}</strong>
          </p>
          <Form.Item name="gender">
            <Select placeholder={t("label_gender")}>
              <Option value="" />
              {gender.map((g) => (
                <Option key={`opt-gender-${g}`} value={g}>
                  {t(g)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <p className="paragraph_form">
            <strong>{t("label_type_school")}</strong>
          </p>
          <Form.Item
            name="schoolType"
            rules={[{ required: true, message: t("message_insert_school") }]}
          >
            <Select placeholder={t("label_school")}>
              {schools.map((s) => (
                <Option key={`opt-school-${s}`} value={s}>
                  {t(s)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: "24px" }}>
        <Col span={24}>{children}</Col>
      </Row>
      <Row justify="center">
        <Col xl={{ span: 4 }} xs={{ span: 10 }}>
          <BaseButton
            className="shadow-button"
            type="primary"
            block
            shape="round"
            htmlType="submit"
          >
            {buttonLabel}
          </BaseButton>
        </Col>
      </Row>
    </Form>
  );
};
export default DataUser;
