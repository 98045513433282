/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Col, Row, Spin, Typography } from "antd";

import { useAuthState } from "../providers/AuthProvider";
import { useTranslation } from "react-i18next";

import client from "../kairosClient";
import { useHistory } from "react-router-dom";

const Ebook = () => {
  const { Title } = Typography;
  const { token } = useAuthState();
  const { t } = useTranslation();
  const history = useHistory();

  const [ebooks, setEbooks] = React.useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);

  console.log("Ebook.init");

  React.useEffect(() => {
    const readEbooks = () => {
      setShowSpinner(true);
      client.ebook
        .getAll(token)
        .then((res) => res.json())
        .then((res) => {
          if (res.eCode) {
            console.log("Library.useEffect.readEbooks error", res);
            //Modale
          } else {
            console.log("Library.useEffect.readEbooks got ebooks", res);
            setEbooks(res);
          }
        })
        .finally(() => setShowSpinner(false));
    };

    console.log("Ebook.useEffect[]");
    readEbooks();
  }, []);

  return (
    <div className="container">
      <Row justify="center">
        <Col md={{ span: 18 }} xs={{ span: 22 }}>
          <Title
            level={2}
            style={{ textAlign: "center", fontWeight: "bold" }}
            className="title-blue"
          >
            {t("label_library_ebook").toUpperCase()}
          </Title>
          <Spin spinning={showSpinner}>
            <Row justify="center">
              {ebooks.map((ebook) => (
                <Col xl={{ span: 8 }} key={ebook.id}>
                  <Row
                    align="middle"
                    className="ebook"
                    onClick={() => history.push(`/ebook/${ebook.id}`)}
                  >
                    <Col xl={{ span: 5 }} xs={{ span: 8 }}>
                      <img
                        src={ebook.cover}
                        alt={ebook.cover}
                        width="100%"
                        style={{ padding: "0px 5px", objectFit: "cover" }}
                      />
                    </Col>
                    <Col
                      xl={{ span: 19 }}
                      xs={{ span: 16 }}
                      style={{ textTransform: "uppercase" }}
                    >
                      <Title level={4} className="title-blue">
                        <strong>{ebook.title}</strong>
                      </Title>
                      <p>{ebook.author}</p>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
export default Ebook;
