/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import dayjs from "dayjs";
import { Button, Col, Collapse, Row, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useAuthState } from "../providers/AuthProvider";

import client from "../kairosClient";
import Loading from "./Loading";
import { BaseButton } from "../components";
import Coppa from "../images/coppa.png";
import Quiz from "../images/quiz.png";

const Profile = ({ secondary }) => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const { t } = useTranslation();
  const history = useHistory();
  const { isProfileExpired, user, token } = useAuthState();

  const [profile, setProfile] = React.useState(null);
  const { Panel } = Collapse;

  React.useEffect(() => {
    const pId = secondary ? user.secondProfileId : user.profileId;
    client.survey
      .getProfile(pId, token)
      .then((res) => res.json())
      .then((res) => {
        if (res.eCode) {
          console.error(
            "UserProfile.useEffect error reading user profile",
            res
          ); //ok
          //showError(res);
        } else {
          setProfile(res);
        }
      })
      .catch((err) => {
        err["eCode"] = 999;
        //showError(err);
        console.error("UserProfile.useEffect unpredictable error", err); //ok
      });
  }, [secondary]);

  const handleProfile = () =>
    history.push(secondary ? "/profile" : "/secondary");
  window.scroll(0, 0);

  const handleSurvey = () => {
    history.push(
      user.profileId && !isProfileExpired() ? "/profile" : "/survey"
    );
  };

  return (
    <>
      {profile ? (
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography.Title
            level={2}
            className="title-blue"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {t(secondary ? "secondary_title" : "profile_title").toUpperCase()}
          </Typography.Title>

          <Row justify="center" style={{ margin: "20px 0px" }}>
            <Col
              md={{ span: 16 }}
              xs={{ span: 22 }}
              className="container-profile"
            >
              <div className="border-container">
                <img
                  src={Coppa}
                  alt="coppa"
                  width="20%"
                  style={{ marginRight: "10px" }}
                />
                <div className="title-blue">
                  {!secondary && (
                    <div style={{ fontSize: "18px" }}>
                      {t("profile_score_intro")}
                    </div>
                  )}
                  <div
                    style={{
                      fontSize: "32px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {profile.title}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Collapse PROFILO*/}
          <Collapse
            className="collapse-style collapse-style-white"
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <RightOutlined
                style={{ fontSize: "30px", color: "#fff" }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              className="collapse-panel"
              header={
                <Row justify="center">
                  <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                    <span className="title-panel">
                      {t("label_survey_profile")}
                    </span>
                  </Col>
                </Row>
              }
              style={{
                borderRadius: "20px",
                borderBottom: "0px solid",
                fontSize: "20px",
              }}
            >
              <Row justify="center" style={{ margin: "20px 0px" }}>
                <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                  {profile.description}
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* Collapse PUNTI DI FORZA*/}
          <Collapse
            className="collapse-style collapse-style-white"
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <RightOutlined
                style={{ fontSize: "30px", color: "#fff" }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              className="collapse-panel"
              header={
                <Row justify="center">
                  <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                    <span className="title-panel">{t("strengths")}</span>
                  </Col>
                </Row>
              }
              style={{
                borderRadius: "20px",
                borderBottom: "0px solid",
                fontSize: "20px",
              }}
            >
              <Row justify="center" style={{ margin: "20px 0px" }}>
                <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                  {profile.strength}
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* Collapse BISOGNI*/}
          <Collapse
            className="collapse-style collapse-style-white"
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <RightOutlined
                style={{ fontSize: "30px", color: "#fff" }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              className="collapse-panel"
              header={
                <Row justify="center">
                  <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                    <span className="title-panel">{t("needs")}</span>
                  </Col>
                </Row>
              }
              style={{
                borderRadius: "20px",
                borderBottom: "0px solid",
                fontSize: "20px",
              }}
            >
              <Row justify="center" style={{ margin: "20px 0px" }}>
                <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                  {profile.need}
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* Collapse CONSIGLI*/}
          <Collapse
            className="collapse-style collapse-style-white"
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <RightOutlined
                style={{ fontSize: "30px", color: "#fff" }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              className="collapse-panel"
              header={
                <Row justify="center">
                  <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                    <span className="title-panel">{t("advices")}</span>
                  </Col>
                </Row>
              }
              style={{
                borderRadius: "20px",
                borderBottom: "0px solid",
                fontSize: "20px",
              }}
            >
              <Row justify="center" style={{ margin: "20px 0px" }}>
                <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                  {profile.advice}
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* Collapse ESEMPI ILLUSTRI*/}

          <Collapse
            className="collapse-style collapse-style-white"
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <RightOutlined
                style={{ fontSize: "30px", color: "#fff" }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              className="collapse-panel"
              header={
                <Row justify="center">
                  <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                    <span className="title-panel">{t("peoples")}</span>
                  </Col>
                </Row>
              }
              style={{
                borderRadius: "20px",
                borderBottom: "0px solid",
                fontSize: "20px",
              }}
            >
              <Row justify="center" style={{ margin: "20px 0px" }}>
                <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                  <ul>
                    {profile.people.split(",").map((p) => (
                      <li key={p.id}>{p}</li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Row justify="center" style={{ marginTop: "20px" }}>
            <Col>
              <BaseButton
                className="shadow-button"
                type="primary"
                shape="round"
                size="large"
                onClick={handleProfile}
              >
                {t(
                  secondary
                    ? "label_primary_profile"
                    : "label_secondary_profile"
                ).toUpperCase()}
              </BaseButton>
            </Col>
          </Row>

          {/* {!secondary && (
          <div className="message" style={{ margin: "40px 10px 20px 10px" }}>
            <span
              className="title-blue"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              {t("message_survey_result_redo_instruction", {
                expirationDate: dayjs.utc(user.expire).format("DD/MM/YYYY"),
              })}
            </span>
          </div>
        )} */}
        </div>
      ) : (
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography.Title
            level={2}
            className="title-blue"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {t(secondary ? "secondary_title" : "profile_title").toUpperCase()}
          </Typography.Title>

          <Row justify="center" style={{ margin: "20px 0px" }}>
            <Col
              md={{ span: 16 }}
              xs={{ span: 22 }}
              className="container-profile"
            >
              <div className="border-container">
                <img
                  src={Quiz}
                  alt="quiz"
                  width="20%"
                  style={{ marginRight: "10px" }}
                />
                <div className="title-blue">
                  <div style={{ fontSize: "22px" }}>
                    {t("message_no_profile")}
                    <br />
                    {t("do_message")}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: "20px" }}>
            <Col>
              <Button
                size="large"
                shape="round"
                className="btn-test-profile"
                onClick={() => handleSurvey()}
              >
                <span style={{ textTransform: "uppercase" }}>
                  <strong>
                    {t(
                      user.totAnswer === 0
                        ? "home_survey"
                        : user.profileId
                        ? "label_profile"
                        : "home_continue_survey"
                    )}
                  </strong>
                </span>
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default Profile;
