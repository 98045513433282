import React, { useState } from "react";
import { Drawer, Grid } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import RightMenu from "./RightMenu";

import logokairos from "../images/takeflight_logo.png";

function MainHeader() {
  const { xl } = Grid.useBreakpoint();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const showDrawer = () => {
    setIsMenuOpen(true);
  };

  const handleOnClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="menuBar">
      <div className="logo">
        <a href="/">
          <img src={logokairos} className="logo-header" alt="logo" />
        </a>
      </div>
      {xl ? (
        <RightMenu />
      ) : (
        <React.Fragment>
          <MenuOutlined
            style={{ fontSize: "30px" }}
            className="barsMenu title-blue"
            onClick={showDrawer}
          >
            <span className="barsBtn"></span>
          </MenuOutlined>
          <Drawer
            placement="right"
            closable={false}
            onClose={handleOnClose}
            visible={isMenuOpen}
          >
            <RightMenu />
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
export default MainHeader;
