/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Spin } from "antd";
import mock from "../images/mock.png";
import appstore from "../images/appstore.png";
import googleplay from "../images/googleplay.png";

import { useTranslation } from "react-i18next";

import client from "../kairosClient";
import { getOS } from "../utils";
import { BaseButton } from "../components";
import { LINKS } from "../constants";

const ViewVideo = () => {
  const { t } = useTranslation();
  const { videocode } = useParams();
  const osInfo = getOS();

  const [error, setError] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [showSpinner, setShowSpinner] = React.useState(false);

  useEffect(() => {
    setShowSpinner(true);
    if (videocode && osInfo) {
      client.view
        .videoByCode(videocode)
        .then((res) => {
          console.log("ViewVideo.useEffect[] got viewVideo response", res);
          return res.json();
        })
        .then((res) => {
          console.log("viewVideo.useEffect[] got viewVideo json response", res);
          if (res.eCode) {
            console.error("ViewView.useEffect[] unpredictable error", res);
            setError(res);
          } else {
            setVideoData(res);
          }
        })
        .finally(() =>
          console.log("viewVideo.useEffect[] viewvideo request completed")
        );
    }
    /*
      - se SO ANDROID o iOS mi arriveranno le info del deep-link video
        Puoi visualizzare il video direttamente sulla app EDUKAIROS.
        Hai già la app EDUKAIROS?
        <deep-link>
        Non ha ancora la app? Scaricala.
        <link allo store>

      - se SO diffenrente (sto unsando un computer) mostro pagina con info per download app
        Puoi visualizzare il video direttamente sulla app EDUKAIROS.
        Scaricala dagli store:
        <link play store> <link apple store>
    */
  }, []);

  const isAndroid = (os) => osInfo.os === "Android";
  const isIOS = (os) => osInfo.os === "iOS";
  const isAndroidOrIOS = (os) => isAndroid(os) || isIOS(os);

  if (!videoData && error) {
    return (
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          margin: "8rem 2rem",
        }}
      >
        <p style={{ fontSize: "26px", fontWeight: "500" }}>
          {error.eCode === "209"
            ? "Il video richiesto non esiste."
            : "Errore imprevisto"}
        </p>
      </div>
    );
  }

  return videoData ? (
    <React.Fragment>
      {/* <div>VideoCode: {videocode}</div>
      <div>OS: {osInfo.os}</div>
      <p>Ecco i link</p>
      <div>
        Link android: <a href={videoData.androidLink}>ANDROID</a>
      </div>
      <div>
        Link ios: <a href={videoData.iosLink}>iOS</a>
      </div> */}
      <div className="container" style={{ padding: "100px 0px" }}>
        <Row
          justify="center"
          className="title-blue message"
          style={{ borderRadius: "0px", backgroundColor: "#ffffff70" }}
        >
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 10 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
            style={{
              height: "450px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={mock} alt="mock" className="img_mock" />
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 10 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="container-img">
              <p
                style={{
                  fontSize: "32px",
                  lineHeight: "1",
                  marginBottom: "0px",
                  textAlign: "center",
                  maxWidth: "500px",
                  fontWeight: "700",
                }}
              >
                {t("label_welcome_education").toUpperCase()}
              </p>
            </div>

            <div
              className="container-p-welcome"
              style={{ marginBottom: "0px" }}
            >
              <p
                style={{
                  fontSize: "26px",
                  fontWeight: "500",
                  marginBottom: "0px",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("message_link_video", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>

              {isAndroidOrIOS(osInfo.os) ? (
                <p
                  style={{ fontSize: "26px", fontWeight: "500" }}
                  dangerouslySetInnerHTML={{
                    __html: t("message_already_app", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></p>
              ) : (
                <p
                  style={{ fontSize: "26px", fontWeight: "500" }}
                  dangerouslySetInnerHTML={{
                    __html: t("download_store", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></p>
              )}
            </div>

            {isAndroidOrIOS(osInfo.os) ? (
              <Row justify="center">
                <Col span={24}>
                  <a
                    href={
                      isAndroid(osInfo.os)
                        ? videoData.androidLink
                        : videoData.iosLink
                    }
                  >
                    <BaseButton
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      size="large"
                    >
                      {t("continued").toUpperCase()}
                    </BaseButton>
                  </a>
                </Col>
              </Row>
            ) : (
              <Row justify="center">
                <Col md={{ span: 9 }} xs={{ span: 14 }}>
                  <a href={LINKS.ios} target="_blank" rel="noreferrer">
                    <img src={appstore} alt="appstore" width="80%" />
                  </a>
                </Col>
                <Col md={{ span: 9 }} xs={{ span: 14 }}>
                  <a href={LINKS.android} target="_blank" rel="noreferrer">
                    <img src={googleplay} alt="googleplay" width="80%" />
                  </a>
                </Col>
              </Row>
            )}
            {isAndroidOrIOS(osInfo.os) ? (
              <>
                <div
                  className="container-p-welcome"
                  style={{ marginBottom: "0px" }}
                >
                  <p
                    style={{ fontSize: "26px", fontWeight: "500" }}
                    dangerouslySetInnerHTML={{
                      __html: t("message_no_app_yet", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></p>
                </div>
                <Row justify="center">
                  <Col md={{ span: 9 }} xs={{ span: 14 }}>
                    <a
                      href={isAndroid(osInfo.os) ? LINKS.android : LINKS.ios}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={isAndroid(osInfo.os) ? googleplay : appstore}
                        alt="appstore"
                        width="80%"
                      />
                    </a>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin spinning={showSpinner} />
    </div>
  );
};
export default ViewVideo;
