/* eslint-disable no-unused-vars */
import React from "react";
import { Grid, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import BaseButton from "./BaseButton";
import dayjs from "dayjs";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const RightMenu = () => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const history = useHistory();
  const { t } = useTranslation();
  const { xl } = Grid.useBreakpoint();
  const { isDoingSurvey, isProfileExpired, logout, user, isAdmin } =
    useAuthState();

  if (!user) {
    return (
      <Menu>
        <Menu.Item key="login">
          <Link to="/login" className="menu-item">
            {t("login").toUpperCase()}
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div
      className="menuCon"
      style={{ marginLeft: "auto", alignItems: "center" }}
    >
      <nav>
        <ul
          className="menu-item responsive-menu"
          style={{
            listStyleType: "none",
          }}
        >
          <li key="welcome">
            <Link to="/welcome">{t("label_welcome").toUpperCase()}</Link>
          </li>

          <li>
            <Link to="/profile">{t("label_survey_profile").toUpperCase()}</Link>
          </li>
          <li>
            <Link to="/ebook">{t("label_ebook").toUpperCase()}</Link>
          </li>
          {/* <Menu.Item key="setting:2">
              <Link to="/podcast" className="menu-item">
                {t("label_podcast").toUpperCase()}
              </Link>
            </Menu.Item> */}
          {/* <Menu.Item key="setting:3">
              <Link to="/video" className="menu-item">
                {t("label_video").toUpperCase()}
              </Link>
            </Menu.Item> */}
          <li key="webinar">
            <Link to="/webinar">{t("label_webinar").toUpperCase()}</Link>
          </li>
          {isAdmin() && (
            <li>
              <Link to="/admin">{t("label_admin").toUpperCase()}</Link>
            </li>
          )}

          <li>
            <Link to="/data">{t("label_my_data").toUpperCase()}</Link>
          </li>

          <li>
            <Link to="/" onClick={() => logout()}>
              {t("logout").toUpperCase()}
            </Link>
          </li>
          {isDoingSurvey && (
            <BaseButton
              type="primary"
              shape="round"
              size="large"
              onClick={() => history.push("/surveyresult")}
              style={{
                textTransform: "uppercase",
                marginBottom: "0px",
              }}
            >
              {t("pause")}
            </BaseButton>
          )}
          {!isDoingSurvey &&
            (user.totQuestion !== user.totAnswer || !isProfileExpired()) && (
              <BaseButton
                type="primary"
                shape="round"
                size="large"
                onClick={() => history.push("/survey")}
                style={{
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                {t(
                  user.totAnswer === 0
                    ? "home_survey"
                    : isProfileExpired() || user.profileId
                    ? "home_survey_redo"
                    : "home_continue_survey"
                )}
              </BaseButton>
            )}
        </ul>
      </nav>
    </div>
  );
};

export default RightMenu;
