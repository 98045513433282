/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Card, Col, Row, Typography } from "antd";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { BaseButton } from "../components";
import Verify from "../images/verifica.png";

const RecoverPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { Title } = Typography;

  const handleOK = () => history.push("/login");

  return (
    <div className="container">
      <div
        className="card-style p-0"
        style={{
          border: "0px solid",
          margin: "auto",
          borderRadius: "20px",
        }}
      >
        <Row align="middle" justify="center">
          <Col
            className="col-blue"
            md={{ span: 12 }}
            xs={{ span: 24 }}
            align="center"
            style={{
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={Verify} alt="LoginImg" width="70%" height="70%" />
          </Col>
          <Col
            md={{ span: 12 }}
            xs={{ span: 24 }}
            className="col-lightblue"
            style={{
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Title
              className="title-blue"
              level={2}
              style={{ textAlign: "center" }}
            >
              {t("label_done").toUpperCase()}
            </Title>

            <Row justify="center">
              <Col span={18}>
                <Title level={5} className="title-blue">
                  <strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("label_password_recover_ok", {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                    ></span>
                  </strong>
                </Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col xl={{ span: 24 }} xs={{ span: 24 }}>
                <BaseButton
                  type="primary"
                  shape="round"
                  block
                  size="large"
                  onClick={handleOK}
                >
                  {t("label_ok").toUpperCase()}
                </BaseButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default RecoverPassword;
