import React from "react";
import { Typography } from "antd";
import Studying from "../images/t4.png";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px 10px",
      }}
    >
        <Title>{t("label_takeflight_title")}</Title>
        <Title level={4}>{t("label_takeflight_subtitle")}</Title>
      
      <img
        src={Studying}
        alt={Studying}
        
      />
    </div>
  );
};
export default Home;
