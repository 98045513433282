/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Collapse, Col, Divider, Row, Typography } from "antd";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";
import { BaseButton } from "../components";
import client from "../kairosClient";
import { Loading } from "../pages";

const Analytics = () => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { Title } = Typography;
  const { token } = useAuthState();

  const [isLoading, setLoading] = React.useState(false);
  const [dati, setDati] = React.useState([]);
  const [search, setSearch] = React.useState({
    from: null,
    to: null,
    company: null,
  });

  React.useEffect(() => {
    setLoading(true);
    client.survey
      .getInstance({}, token)
      .then((res) => res.json())
      .then((res) => {
        console.log("Analytics.useEffect[] instances", res);
        setDati(res);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSubmit = (e) => {
    //e.preventDefault();
    const form = document.getElementById("surveyForm");

    const format = form[0].value;
    form[0].value = "";
    const from = form[1].value;
    const to = form[2].value;
    const company = form[3].value;
    console.log("Analytics.handleSubmit values", format, from, to, company);
    setLoading(true);
    if (format && format.length > 0) {
      client.survey.getInstance({ from, to, company, format }, token, () =>
        setLoading(false)
      );
    } else {
      client.survey
        .getInstance({ from, to, company, format }, token)
        .then((res) => res.json())
        .then((res) => {
          console.log("Analytics.useEffect[] instances", res);
          setDati(res);
        })
        .finally(() => setLoading(false));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="collapse-style"
        style={{
          backgroundColor: "#FFF",
          borderRadius: "10px",
          padding: "0px 20px",
          marginBottom: "20px",

          minWidth: "1240px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <form
          id="surveyForm"
          onSubmit={handleSubmit}
          style={{
            padding: "20px 0px",
            borderBottom: "2px solid #E3EEF3",
          }}
        >
          <input id="exportType" type="hidden" value="" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "grid",
                gap: "25px",
                gridTemplateColumns: "20px 200px 20px 200px 200px 100px 100px",
                alignItems: "center",
              }}
            >
              <span className="date-form">{t("label_from")}</span>
              <input
                id="dateFrom"
                name="dateForm"
                style={{ border: "0px", borderBottom: "2px solid #035E86" }}
                type="date"
                value={search?.from}
                onInput={(e) => {
                  const value = e.target.value;
                  setSearch((prev) => ({ ...prev, from: value }));
                }}
              />
              <span className="date-form">{t("label_to")}</span>
              <input
                id="dateTo"
                style={{ border: "0px", borderBottom: "2px solid #035E86" }}
                type="date"
                value={search?.to}
                onInput={(e) => {
                  const value = e.target.value;
                  setSearch((prev) => ({ ...prev, to: value }));
                }}
              />

              <input
                type="text"
                id="company"
                value={search?.company}
                placeholder={t("label_company_name")}
                style={{ border: "0px", borderBottom: "2px solid #035E86" }}
                onInput={(e) => {
                  const value = e.target.value;
                  setSearch((prev) => ({ ...prev, company: value }));
                }}
              />

              <SearchOutlined
                style={{
                  cursor: "pointer",
                  fontSize: "30px",
                  color: "#035E86",
                }}
                onClick={handleSubmit}
              />
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <BaseButton
                className="shadow-button"
                type="primary"
                block
                shape="round"
                htmlType="button"
                onClick={(e) => {
                  document.getElementById("exportType").value = "CSV";
                  handleSubmit(e);
                }}
                style={{ marginBottom: "0px" }}
              >
                CSV
              </BaseButton>
              <BaseButton
                className="shadow-button"
                type="primary"
                block
                shape="round"
                htmlType="button"
                onClick={(e) => {
                  document.getElementById("exportType").value = "XLS";
                  handleSubmit(e);
                }}
                style={{ marginBottom: "0px" }}
              >
                XLS
              </BaseButton>
            </div>
          </div>
        </form>

        <Row justify="center">
          <Col xl={{ span: 24 }} xs={{ span: 22 }}>
            <div
              style={{
                padding: "0px 16px",
                display: "grid",
                gridTemplateColumns: "2fr 200px 1fr 2fr 1fr",
                columnGap: "24px",
                fontSize: "20px",
                textTransform: "uppercase",
                color: "#035E86",
                margin: "20px 40px 0px 40px",
              }}
            >
              <strong>
                {t("label_name")} {t("label_surname")}
              </strong>
              <strong>{t("birth_year")}</strong>
              <strong>{t("activity")}</strong>
              <strong>{t("label_company_name")}</strong>
              <strong>{t("label_survey_profile")}</strong>
            </div>
          </Col>
        </Row>
        {dati.length > 0 ? (
          dati.map((d) => (
            <Collapse
              key={d.id}
              style={{ width: "100%" }}
              className="collapse-style"
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
              expandIcon={({ isActive }) => (
                <RightOutlined
                  style={{ fontSize: "30px", color: "#fff" }}
                  rotate={isActive ? 90 : 0}
                />
              )}
            >
              <Panel
                style={{
                  backgroundColor: "#035E86",
                  borderRadius: "10px",
                  borderBottom: "0px solid",
                }}
                header={
                  <Row justify="center">
                    <Col xl={{ span: 24 }} xs={{ span: 22 }}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 200px 1fr 2fr 1fr",
                          columnGap: "24px",
                          fontSize: "20px",
                          textTransform: "uppercase",
                          color: "#fff",
                          margin: "0px 16px 0px 40px",
                        }}
                      >
                        <span>
                          {d.user.name} {d.user.surname}
                        </span>
                        <span>{d.user.birthYear}</span>
                        <span>{t(d.user.schoolType)}</span>
                        <span>{d.user.company?.name}</span>
                        <span>{d.profile.title}</span>
                      </div>
                    </Col>
                  </Row>
                }
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "40px 1fr 70px",
                    columnGap: "20px",
                    fontSize: "18px",
                    margin: "0px 40px 0px 40px",
                  }}
                >
                  <strong style={{ textAlign: "right" }}>
                    {t("question_number")}
                  </strong>
                  <strong>{t("question")}</strong>
                  <strong style={{ textAlign: "right" }}>{t("score")}</strong>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "40px 1fr 70px",
                    columnGap: "20px",
                    fontSize: "18px",
                    margin: "0px 40px",
                  }}
                >
                  {d.questions.map((q, index) => (
                    <React.Fragment key={q.id}>
                      <span style={{ textAlign: "right" }}>{index + 1}</span>
                      <span>{q.text}</span>
                      <span style={{ textAlign: "right" }}>
                        {q.answer.score}
                      </span>
                      <Divider
                        style={{
                          margin: "5px 0px",
                          gridColumnStart: 1,
                          gridColumnEnd: 4,
                        }}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </Panel>
            </Collapse>
          ))
        ) : (
          <Title
            level={3}
            style={{ textAlign: "center", fontWeight: "bold" }}
            className="title-blue"
          >
            {t("no_search_result").toUpperCase()}
          </Title>
        )}
      </div>
    </div>
  );
};
export default Analytics;
