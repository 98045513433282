import React from "react";
import { Col, Row, Typography } from "antd";

const PrivacyPolicy = () => {
  const { Paragraph, Title } = Typography;

  return (
    <Row style={{ margin: "20px 0px" }} justify="center">
      <Col
        xl={{ span: 12 }}
        xs={{ span: 20 }}
        style={{
          backgroundColor: "#E7F0F4",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <Title level={2} style={{ textAlign: "center" }}>
          INFORMATIVA E POLICY UNDER 14
        </Title>
        <Title level={4}>Dai 14 anni in giù… Benvenuto!</Title>
        <Paragraph>
          Prima che tuo figlio inizi ad utilizzare la nostra app, vogliamo
          assicurarci che tu comprenda come funziona, quali informazioni
          raccoglie e quali garanzie ti diamo a tutela della vostra privacy.
        </Paragraph>
        <Title level={5}>La nostra applicazione raccoglie:</Title>
        <ul>
          <li>
            informazioni basate sull’uso della stessa app al solo fine di
            soddisfare le esigenze di ogni ragazzo e supportarlo meglio nel suo
            percorso di apprendimento.
          </li>
          <li>
            informazioni sul dispositivo e sulle app utilizzate quali: tipo e
            impostazioni del dispositivo, modello hardware e versione del
            sistema operativo.
          </li>
          <li>informazioni di registro sugli eventi del dispositivo.</li>
          <li>
            numeri di applicazione univoci, come il numero di versione
            dell’applicazione.
          </li>
          <li>
            identificatori univoci che vengono utilizzati per raccogliere o
            archiviare informazioni su una app o su un dispositivo, come la
            lingua preferita, l’attività dell’app o altre impostazioni.
          </li>
          <li>
            eventuali interazioni con i contenuti, annunci e aggiornamenti
            dell’app.
          </li>
        </ul>
        <Title level={5}>Come utilizziamo questi dati?</Title>
        <Paragraph>
          Le informazioni che raccogliamo vengono utilizzate solo per scopi
          operativi interni, come determinare la lingua preferita, oltre che per
          offrire il profilo di studente che risulta dalla partecipazione alle
          domande e in generale per migliorare il nostro prodotto.
        </Paragraph>
        <Paragraph>
          Le informazioni fornite sul profilo studente vengono trattate in
          maniera aggregata e non correlata agli identificativi.
        </Paragraph>
        <Paragraph>
          L’applicazione non consente all’utilizzatore di condividere
          informazioni personali con terze parti o di renderle disponibili al
          pubblico.
        </Paragraph>
        <Paragraph>
          Le informazioni raccolte non verranno divulgate in nessuna forma a
          terzi al di fuori di EDUKAIROS, né per finalità commerciali, né per
          finalità statistiche, a meno che non sia prestato il consenso in forma
          espressa dei genitori.
        </Paragraph>
        <Paragraph>
          Le informazioni raccolte potranno essere condivise con organizzazioni
          o soggetti esterni alla nostra azienda per i seguenti motivi legali:
        </Paragraph>
        <ul>
          <li>su ordine dell’autorità giudiziaria.</li>
          <li>
            se, applicando il principio della buona fede, appare necessario al
            fine di soddisfare qualsiasi legge, regolamento, ordinanza,
            procedimento legale, protocollo istituzionale applicabile sulla base
            delle leggi nazionali in materia di competenza per territorio.
          </li>
          <li>
            determinare i termini di servizio applicabili, inclusa l’indagine su
            potenziali violazioni.
          </li>
          <li>
            prevenire, rilevare, combattere frodi, problemi tecnici o di
            sicurezza.
          </li>
          <li>
            proteggere i nostri diritti, proprietà e sicurezza della app nei
            limiti e nei modi consentiti dalla legge nazionale dello Stato
            Italiano, ovvero su istanza delle competenti autorità di uno Stato
            estero (secondo i criteri stabiliti dall’art. 204 c.p.c., nonché
            dalla Convenzione dell’Aja del 1970).
          </li>
        </ul>
        <Paragraph>
          Il genitore o altro accudiente titolare del dispositivo può
          disinstallare e reinstallare la app utilizzando altri dispositivi per
          ripristinare gli identificativi specifici della app utili per
          raccogliere informazioni.
        </Paragraph>
        <Paragraph>
          Consulta la nostra <b>privacy policy</b> per ogni ulteriore
          informazione.
        </Paragraph>
        <Title level={4}>PRIVACY POLICY</Title>
        <Paragraph>
          La seguente privacy policy descrive quali informazioni vengono
          raccolte dalla app EDUKAIROS e come vengono utlizzate da parte di
          Takeflight srl attraverso i dispositivi mobili sui quali viene
          installata.
        </Paragraph>
        <Paragraph>
          <b>Importante:</b> la app EDUKAIROS non ha la finalità di raccogliere
          e non raccoglie:
        </Paragraph>
        <ul>
          <li>informazioni personali sull’identità dei minori</li>
          <li>immagini dei minori</li>
          <li>
            altri dati che rendano identificabili i minori di anni 14, nemmeno a
            fini commerciali.
          </li>
        </ul>
        <Paragraph>
          Quando la app viene scaricata su un dispositivo mobile non è richiesta
          alcuna informazione né viene raccolta alcuna informazione riguardo al
          dispositivo utilizzato, fatta eccezione per le informazioni non di
          carattere personale inerenti la durata dell’utilizzo dell’app, a come
          viene utilizzata, alla visualizzazione sullo schermo e all’operatività
          interna alla app stessa.
        </Paragraph>
        <Paragraph>
          Nessuna delle predette informazioni non personali viene fornita a
          terzi al di fuori della nostra società.
        </Paragraph>
        <Title level={5}>Riferimenti di Legge.</Title>
        <Paragraph>
          La app è stata elaborata nel rispetto della normativa vigente
          all’interno dell’UE e nello stato Italiano a tutela dei minori.
        </Paragraph>
        <Paragraph>
          In particolare, la nostra app tiene conto di quanto disposto dalla L.
          n. 196/2003, art. 2 quinquies, così come modificato dal D.Lgs. n.
          101/2018, che consente al minore di anni 18 di poter esprimere il
          consenso al trattamento dei propri dati personali solo se abbia
          compiuto i 14 anni.
        </Paragraph>
        <Paragraph>
          Per questo per gli studenti che non hanno ancora compiuto 14 anni, la
          nostra app non raccoglie dati personali in forme che possano rendere
          identificabile l’utente, nemmeno al fine di proporre offerte
          commerciali. La nostra app ha valore di sondaggio interno a EDUKAIROS,
          al termine del quale emerge un profilo studente del tutto anonimo che
          solo l’utilizzatore sa essere riferito a se stesso.
        </Paragraph>
        <Paragraph>
          Le risposte al questionario “che tipo di studente sei” fornite da
          utenti infraquattordicenni vengono trattate anonimamente e in forma
          aggregata dallo staff di EDUKAIROS, al solo scopo di migliorare la
          nostra offerta di strumenti di supporto allo studio (e-book ad
          esempio..).
        </Paragraph>
        <Paragraph>
          Si tratta, quindi, di un utilizzo equivalente alla compilazione di un
          customer satisfaction che non richiede consenso.
        </Paragraph>
        <Paragraph>
          Dal momento che ogni applicazione mobile richiede il possesso di uno
          smartphone o di un tablet e la contestuale intestazione di una scheda
          con numero telefonico o di un account e-mail, si invita il minore che
          li utilizza da solo o che vi accede autonomamente ad informare i
          propri genitori e/o il legittimo proprietario del dispositivo.
        </Paragraph>
        <Title level={5}>Cookies</Title>
        <Paragraph>
          Se viene visitato il nostro potrebbero esserti inviati cookies in
          grado di identificare il browser.
        </Paragraph>

        <Paragraph>
          Usiamo i cookies per migliorare la qualità del nostro servizio,
          memorizzando le preferenze dell’utente e monitorando le informazioni
          di utilizzo.
        </Paragraph>
        <Paragraph>
          La maggior parte dei browser consente di disabilitare i cookies o di
          impostare un avviso che si attivi nel momento stesso in cui
          iniziassero ad arrivare sul tuo dispositivo.
        </Paragraph>
        <Title level={5}>Contattaci</Title>
        <Paragraph>
          Per qualsiasi questione o domanda relativa alla nostra privacy policy,
          è possibile inviare una mail a{" "}
          <a href="mailto:takeflight@takeflight.it">takeflight@takeflight.it</a>
          .
        </Paragraph>
      </Col>
    </Row>
  );
};
export default PrivacyPolicy;
