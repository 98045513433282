import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAuthState } from "../providers/AuthProvider";

import { Col, Row, Typography } from "antd";
import Elearning from "../images/elearning.png";
import Website from "../images/website.png";
import Profile from "../images/profile.png";
import Media from "../images/media.png";


const CarouselWelcome = () => {
    const { t } = useTranslation();
    const { Title } = Typography;
    const { user } = useAuthState();

    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "40px",
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: true,
    };

    return (
        <div style={{ width: 'auto' }}>
            <Slider {...settings} className="dot-blue" style={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px' }}>
                <div>
                    <Title level={3} style={{ margin: '30px 0px 20px' }}>
                        {t('label_first_steps').toUpperCase()}
                    </Title>
                    <img src={Website} alt="studying" width="40%" />
                    <Row justify="center" style={{ margin: '20px 0px' }}>
                        <Col xl={{ span: 22 }} style={{ lineHeight: '1.3' }}>
                            <p style={{ fontSize: '24px', textAlign: 'left', marginBottom: '0px' }}>
                                {t('label_hello_user', { name: user.name })}
                            </p>
                            <p style={{ fontSize: '24px', textAlign: 'left', marginBottom: '0px' }}>
                                {t('message_first_steps')}
                            </p>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Title level={3} style={{ margin: '30px 0px 20px' }}>
                        {t('label_test').toUpperCase()}
                    </Title>
                    <img src={Elearning} alt="studying" width="40%" />
                    <Row justify="center" style={{ margin: '20px 0px' }}>
                        <Col xl={{ span: 22 }} style={{ lineHeight: '1.3' }}>
                            <p style={{ fontSize: '24px', textAlign: 'left', marginBottom: '0px' }}>
                                {t('message_test')}
                            </p>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Title level={3} style={{ margin: '30px 0px 20px' }}>
                        {t('label_profile').toUpperCase()}
                    </Title>
                    <img src={Profile} alt="studying" width="40%" />
                    <Row justify="center" style={{ margin: '20px 0px' }}>
                        <Col xl={{ span: 22 }} style={{ lineHeight: '1.3' }}>
                            <p style={{ fontSize: '24px', textAlign: 'left', marginBottom: '0px' }}>
                                {t('message_profile')}
                            </p>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Title level={3} style={{ margin: '30px 0px 20px' }}>
                        {t('label_media_content').toUpperCase()}
                    </Title>
                    <img src={Media} alt="studying" width="40%" />
                    <Row justify="center" style={{ margin: '20px 0px' }}>
                        <Col xl={{ span: 22 }} style={{ lineHeight: '1.3' }}>
                            <p style={{ fontSize: '24px', textAlign: 'left', marginBottom: '0px' }}>
                                {t('message_media_content')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Slider>
        </div>
    )
}
export default CarouselWelcome;