import React from "react";
import { Col, Row, Typography } from "antd";

const CookiePolicy = () => {
  const { Title } = Typography;
  React.useEffect(() => {
    const script = document.createElement("script");
    script.id = "CookieDeclaration";
    script.src =
      "https://consent.cookiebot.com/ff24da40-f6c7-41d1-82c1-c84b8a29b2b6/cd.js";
    script.type = "text/javascript";
    const cookie = document.getElementById("cookie");
    console.log("Cookie", cookie);
    if (cookie) {
      cookie.appendChild(script);
    }

    //document.body.appendChild(script);
  }, []);
  console.log("Cookie policy");
  return (
    <Row style={{ margin: "20px 0px" }} justify="center">
      <Col
        xl={{ span: 12 }}
        xs={{ span: 20 }}
        style={{
          backgroundColor: "#E7F0F4",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <Title level={2} style={{ textAlign: "center" }}>
          COOKIE POLICY
        </Title>
        <div id="cookie"></div>
      </Col>
    </Row>
  );
};
export default CookiePolicy;
